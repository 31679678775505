.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.svg_container, .text_svg_wrapper {
  position: relative;
  width: 100%;
}

.svg_container {
  width: 50%;
}

.absolute_text_info {
  position: absolute;
  width: 250px;
  background: rgba(255, 255, 255, 0.85);
  box-sizing: border-box;
  padding: 10px 15px;
  top: 325px;
  left: 125px;
}

.info {
  font-size: 14px;
  margin: 0px;
}

.svg_questions_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.question_text, .mobile_question_text {
  max-width: 290px;
  font-size: 15px;
  font-weight: 500;
  color: #424242;
  margin-right: 10px;
  background: #f5f5f5;
  padding: 10px 15px;
}

.mobile_question_text {
  max-width: 100%;
  text-align: center;
}

#second_question {
  margin-right: 0px;
  margin-left: 10px;
}

.buttons_container {
  display: flex;
  align-items: center;
}

.margined_sub_button {
  margin-left: 20px;
}

.subscription_button {
  padding: 8px 15px;
  margin-top: 10px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #ED4E5C;
  width: 110px;
  height: 25px;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.06);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.06);
  background-position: center;
  border-radius: 3px;
  cursor: pointer;
  -webkit-transition: 0.25s ease-in;
  -o-transition: 0.25s ease-in;
  transition: 0.25s ease-in;
}

.button_text_wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.subscription_button:hover {
  background: #e04653 -o-radial-gradient(circle, transparent 1%, #ED4E5C 1%) center/15000%;
  background: #e04653 radial-gradient(circle, transparent 1%, #ED4E5C 1%) center/15000%;
}
.subscription_button:active {
  background-color: #ED4E5C;
  background-size: 100%;
  -webkit-transition: background 0s;
  -o-transition: background 0s;
  transition: background 0s;
}

.subscription_button_text {
  font-size: 13px;
  margin: 5px 0px;
  color: white;
  margin-right: 5px;
  font-weight: 500;
  text-transform: uppercase;
}

.subscription_button_icon {
  color: #eee;
}

.mobile_question_text_cont {
  display: none;
}

@media screen and (max-width: 1400px){
  .question_text {
    max-width: 250px;
  }
}

@media screen and (max-width: 1325px){
  .question_text {
    max-width: 200px;
  }
}

@media screen and (max-width: 1250px){
  .question_text {
    display: none;
  }

  .svg_questions_wrapper {
    flex-direction: column;
  }
  .absolute_text_info {
    left: 100px;
  }

  .mobile_question_text_cont {
    display: flex;
    flex-direction: row;
  }

}

@media screen and (max-width: 1125px){
  .absolute_text_info {
    left: 125px;
  }
}

@media screen and (max-width: 1050px){
  .svg_container {
    width: 65%;
  }
  .absolute_text_info {
    left: 100px;
  }
}

@media screen and (max-width: 940px){
  .absolute_text_info {
    left: 90px;
  }
}

@media screen and (max-width: 900px){
  .svg_container {
    width: 75%;
  }
  .absolute_text_info {
    left: 140px;
  }
}

@media screen and (max-width: 800px){
  .svg_container {
    width: 85%;
  }
  .absolute_text_info {
    left: 150px;
  }
}

@media screen and (max-width: 750px){
  .svg_container {
    width: 95%;
  }
  .absolute_text_info {
    left: 125px;
  }
}

@media screen and (max-width: 600px){
  .svg_container {
    width: 100%;
  }
  .absolute_text_info {
    left: 100px;
  }
}
