html, body, .App {
  font-family: 'Barlow', sans-serif;
  overflow-x: hidden;
}

.container {
  padding: 0px 140px;
}

.section_title {
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  color: #00A4B2;
  margin: 15px 25px;
}

.section_sub_title {
  margin: 5px 0px;
  margin-bottom: 15px;
  text-align: center;
  font-size: 14px;
  margin-top: -5px;
}

.title_separator {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}

.title_separator_lines {
  height: 1px;
  background: #00a4b2;
  width: 80px;
}

.stop-scrolling {
  height: 100vh;
  overflow: hidden;
}

.tooltip {
  position: absolute;
  width: 200px;
  background: rgba(255, 255, 255, 0.8);
  padding: 8px 10px;
  box-sizing: border-box;
  z-index: 4;
}

.tooltip > p {
  font-size: 12px;
  margin: 0px;
}

.margined_vis {
  margin-top: 20px;
}

@media screen and (max-width: 1150px) {
  .container {
    padding: 0px 80px;
  }
}

@media screen and (max-width: 650px) {
  .container {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 500px) {
  .container {
    padding: 0px 20px;
  }
}
