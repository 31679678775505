.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .container {
    width: 85%;
    margin-left: 7.5%;
  }
}

@media screen and (min-width: 1000px) {
  .container {
    width: 82%;
    margin-left: 9%;
  }
}
