.flexed_text_container {
  background-image: linear-gradient(to right, #00b0b8, #38b7b4, #55beb0, #6dc5ae, #83cbac);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 140px 70px 140px;
  position: relative;
  cursor: pointer;
}

.text {
  color: white;
  font-weight: 700;
  font-size: 22px;
  margin-right: 150px;
}

.report_image {
  width: 128px;
  height: 182px;
  background: white;
  -webkit-box-shadow: 8px 8px 0px 0px rgba(227,227,227,1);
  box-shadow: 8px 8px 0px 0px rgba(227,227,227,1);
  -webkit-transition: 0.275s ease-in-out;
  -o-transition: 0.275s ease-in-out;
  transition: 0.275s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.report_image_src {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.report_image_overlay_text {
  font-size: 13px;
  color: white;
  position: relative;
  z-index: 3;
  font-weight: 600;
}

.flexed_text_container:hover > .report_container > .report_image {
  -webkit-box-shadow: 5px 5px 0px 0px rgba(210,210,210,1);
  box-shadow: 5px 5px 0px 0px rgba(210,210,210,1);
}

.flexed_text_container:hover > .absolute_colored_border > .absolute_border_red {
  height: 0px;
}

.absolute_colored_border {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 10px;
  overflow-x: hidden;
}

.absolute_border_yellow {
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: #E1AE5F;
  width: 100%;
  height: 10px;
}


.absolute_border_red {
  position: absolute;
  bottom: 0px;
  right: -10px;
  background: #ED4E5C;
  transform: skewX(-30deg);
  width: 35%;
  height: 10px;
  z-index: 2;
  -webkit-transition: 0.30s ease-in-out;
  -o-transition: 0.30s ease-in-out;
  transition: 0.30s ease-in-out;
}

.white_background {
  width: 100%;
  padding: 10px 0px;
}

.white_flexed_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0px 70px;
  box-sizing: border-box;
}

.logo_container {
  display: flex;
  align-items: center;
}

.stdf_logo {
  width: 80px;
}

.unido_logo {
  width: 60px;
  margin-left: 15px;
}

.copyright_text {
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
}


@media screen and (max-width: 1150px) {
  .flexed_text_container {
    padding: 50px 80px 70px 80px;
  }

  .text {
    margin-right: 80px;
  }
}

@media screen and (max-width: 800px) {
  .white_flexed_cont {
    flex-direction: column;
    align-items: flex-start;
  }
  .text {
    margin-right: 40px;
    font-size: 18px;
  }
}

@media screen and (max-width: 650px) {
  .flexed_text_container {
    padding: 50px 40px 70px 40px;
  }
  .text {
    font-size: 16px;
  }
  .white_flexed_cont {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 500px) {
  .flexed_text_container {
    padding: 50px 20px 70px 20px;
  }
  .white_flexed_cont {
    padding: 0px 20px;
  }
  .report_image {
    width: 120px;
    height: 150px;
  }
  .stdf_logo {
    width: 70px;
    margin-bottom: 5px;
  }

  .unido_logo {
    width: 50px;
    margin-left: 15px;
    margin-bottom: 5px;
  }
  .copyright_text {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
