.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.advantage_container, .disadvantage_container {
  width: 50%;
}

@media screen and (max-width: 725px) {
  .container {
    flex-direction: column;
  }
  .advantage_container, .disadvantage_container {
    width: 100%;
  }
}
