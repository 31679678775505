.container {
  height: 425px;
  overflow: hidden;
  position: relative;
  margin: 75px 0px;
}

.parallax_img {
  width: 100%;
  height: 100%;
}

.text_container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 3;
  top: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 140px;
  box-sizing: border-box;
}

.gradient_overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-image: linear-gradient(to bottom, rgba(101, 101, 101, 0.08), rgba(94, 94, 94, 0.10), rgba(88, 88, 88, 0.10), rgba(81, 81, 81, 0.12), rgba(75, 75, 75, 0.15), rgba(69, 69, 69, 0.18), rgba(62, 62, 62, 0.22), rgba(56, 56, 56, 0.25), rgba(48, 48, 48, 0.35), rgba(40, 40, 40, 0.44), rgba(32, 32, 32, 0.50), rgba(25, 25, 25, 0.55));
}

.text {
  font-size: 22px;
  margin-bottom: 52px;
  color: white;
  text-align: center;
  font-weight: 600;
}

.absolute_border {
  position: absolute;
  bottom: 0px;
  right: -10px;
  height: 15px;
  width: 22%;
  transform: skewX(-30deg);
  background: rgba(237, 78, 92, 0.75);
  z-index: 3;
}


@media screen and (max-width: 1150px) {
  .text_container {
    padding: 0px 80px;
  }
}

@media screen and (max-width: 650px) {
  .text_container {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 635px) {
  .container {
    height: 375px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    height: 305px;
  }

  .text {
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  .text_container {
    padding: 0px 20px;
  }
}

@media screen and (max-width: 455px) {
  .container {
    height: 275px;
  }
  .text {
    font-size: 18px;
  }
}

@media screen and (max-width: 405px) {
  .container {
    height: 225px;
  }
  .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 330px) {
  .container {
    height: 200px;
  }
  .text {
    font-size: 14px;
  }
}
