.container {
  width: 100%;
  height: 100vh;
  margin-bottom: 50px;
  overflow-x: hidden;
  position: relative;
}

.left_image_container {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 55%;
  height: 100%;
  overflow: hidden;
}

.left_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right_image_container {
  position: absolute;
  right: -10%;
  top: 0px;
  width: 60.5%;
  height: 100%;
  transform: skewX(-10deg);
  z-index: 3;
  overflow: hidden;
}

.right_image_skewCancel {
  width: 110%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.right_image {
  width: 110%;
  position: absolute;
  left: -10%;
  height: 100%;
  object-fit: cover;
  transform: skewX(10deg);
}

.title_container {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title_colored {
  background: rgba(255,255,255,0.925);
  border-radius: 8px;
  max-width: 400px;
  padding: 10px 15px;
  padding-bottom: 40px;
  box-sizing: content-box;
  position: relative;
}

.icon_container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.stdf_logo {
  height: 38px;
}

.unido_logo {
  height: 80px;
  margin-left: 20px;
}

.title {
  font-size: 24px;
  font-weight: 600;
  color: #525252;
  margin-bottom: 14px;
  margin-top: 2px;
  text-align: center;
  padding: 0px 15px;
}

.description {
  font-size: 13px;
  text-align: center;
  padding: 0px 4px;
  margin: 8px 0px;
  margin-bottom: 12px;
}

.bottom_button {
  position: absolute;
  bottom: 0px;
  width: 130px;
  padding: 5px 12px;
  box-sizing: content-box;
  background: #ED4E5C;
  left: calc(50% - 65px);
  display: flex;
  justify-content: center;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.button_text {
  font-size: 13px;
  font-weight: 600;
  margin: 0px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 8px;
  color: #fff;
}

.scroll_down_icon {
  width: 21px;
  height: 21px;
  background: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
}

.scroll_down_icon_arrow {
  font-size: 17px;
  color: #5C607F;
}

.download_report_button {
  position: absolute;
  top: 20px;
  background: rgba(237,78,92,0.925);
  padding: 12px 12px;
  border-radius: 5px;
  width: 151px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  -webkit-box-shadow: 0 0 12px 3px rgba(0,0,0,.06);
          box-shadow: 0 0 12px 3px rgba(0,0,0,.06);
  cursor: pointer;
}

.download_report_text {
  margin: 0px;
  margin-right: 8px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}

@media screen and (max-width: 1125px) {
  .right_image_container {
    right: -5%;
  }
}

@media screen and (max-width: 650px) {
  .right_image_container {
    display: none;
  }
  .left_image_container {
    width: 100%;
  }
  .title_colored {
    width: 90%;
    max-width: unset;
  }
}
