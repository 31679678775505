.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 800px) {
  .container {
    width: 80%;
    margin-left: 10%;
  }
}

@media screen and (min-width: 1000px) {
  .container {
    width: 60%;
    margin-left: 20%;
  }
}
